@font-face {
  font-family: "Inter";
  font-weight: 400 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("./content/fonts/Inter-roman.var.woff2") format("woff2");
}

@font-face {
  font-family: "Inter";
  font-weight: 400 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("./content/fonts/Inter-italic.var.woff2") format("woff2");
}

html {
  font-family: "Inter var", sans-serif;
  overflow-x: hidden;
  background-color: #192B5A;
}

body {
  min-height: 100vh;
  min-width: 20rem;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #f9f8f7;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd,
input,
select,
textarea {
  margin: 0;
}

a {
  text-decoration: none;
  text-decoration-skip-ink: auto;
}
a:link {
  color: #f9f8f7;
  background-color: transparent;
  text-decoration: none;
}
a:visited {
  color: #f9f8f7;
}

/* mouse over link */
a:hover {
  color: #f9f8f7;
}

/* selected link */
a:active {
  color: #f9f8f7;
}

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
  border-radius: 0;
}

input:active,
input:focus,
textarea:active,
textarea:focus,
select:active,
select:focus {
  border-radius: 0;
}

span.hlt {
  background-color: #e7c7e0;
  padding: 0.125rem 0.25rem;
}

@media only screen and (prefers-color-scheme: dark) {
  html {
    background-color: #131211;
  }

  body {
    color: #f9f8f7;
  }

  span.hlt {
    background-color: #a02084;
  }
}

.sb-show-main {
  background-color: white;
}
